/* You can add global styles to this file, and also import other style files */

@import "./rm-fonts.scss";
@import "./rm-bootstrap.scss";
@import "../node_modules/angular-calendar/css/angular-calendar.css";
@import "~@angular/material/prebuilt-themes/indigo-pink.css";

:root {
  --button-background-color: #006f81;
  --button-reject-background-color: #E60A20;
  --default-box-shadow: 0px 0px 0px 0.5px rgb(50 50 93 / 10%), 0px 2px 5px 0px rgb(50 50 93 / 10%), 0px 1px 1.5px 0px rgb(0 0 0 / 7%);
  --button-border-radius:10px;
}

html, body {
    width: 100vw;
    height: 100vh;
}

body {
  padding: 0;
  margin: 0;
  overflow: hidden;
  //background-color:#f4f4f4 !important;
  background-color:#ffffff !important;
}

.wrapper {
  display: flex;
  flex-direction: column;

  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: stretch;
  align-content: stretch;

  height: calc(100vh - 70px);
  overflow-x: auto;
  overflow-y: auto;
}

.content {
  display: flex;
  flex: 1;
}

.rm-splash {
  width: 100vw;
  height: 100vh;

  // @include rm-background-gradient;
}

.rm-map-card {
  width:  calc( ( 100vw - 120px ) / 5 );
}

.rm-map {
  @extend .rm-map-card;
  height: 800px;
  // height: calc( ( 100vw - 120px ) / 5 );
  height: calc( ( 100vw - 120px ) / 5 );
}

.rm-map-image-viewport {
  @extend .rm-map;
  overflow: hidden;
}

.editWorkorderCarouselHolder {
  // height:50% !important;

  .ui-carousel-container {
    height:100%;
  }

  .ui-carousel-prev,.ui-carousel-next,.ui-carousel-dots-container {
    display:none;
  }

  // .gm-style-pbc ~ div {
  //   cursor:crosshair;
  // }
}

.gm-style > div {
  cursor:crosshair !important;
}

.platesHolder {
  .ui-card {
    cursor:pointer;
  }
}

body .ui-accordion .ui-accordion-header a {
  padding: 0;
  font-size: 12px;
}

.MicrosoftMap .birdseyeV2InfoExitControl {
  display:none;
}

.MicrosoftMap .NavBarButton_Container {
  display: none;
}

.gm-style .gm-style-iw-t::after {
  transform: translate(-50%,-50%) rotate(135deg) !important;
  background-color:red !important;
  box-shadow: 0px 0px 0px 0px !important;
}

.p-tooltip-text {
  font-size: 11px;
}

.pageTitle {
  margin-top:20px;
  // margin-left:20px;
  padding-bottom:10px;
}

.rm-danger-textbox {
  border:1px solid #E60A20 !important;
}

.rm-danger-symbol {
  color:#E60A20 !important;
}

.rm-action-button {
  color:#000000 !important;
}

.rm-valid-symbol {
  color:#2edb6b !important;
}

.rm-valid-textbox {
  border:1px solid #2edb6b !important;
}

.p-button.p-button-icon-only.p-button-rounded.p-button:focus {
  box-shadow:none;
  color:#007ad9;
}

p-tag {
  .p-tag {
    border-radius: 10px !important;
    background-color: var(--button-background-color);
  }

  .p-tag-value {
    font-size:10px;
  }
}

.flag-Flagged {
  background-color:#ad101f !important;
}

.flag-ReWork {
  background-color:#eded39 !important;
}

.flag-Fixed {
  background-color:#2edb6b !important;
}

.p-button:enabled:hover {
  background-color: var(--button-background-color);
  border-color: var(--button-background-color);
}

.p-button.p-button-text:enabled:hover {
  background: none;
  color:#000000;
}

.p-tabview .p-tabview-nav {
  background: #ffffff;
  border: 1px solid #dddddd;
  border-width: 0 0 2px 0;
}

.p-tabview .p-tabview-nav li {
  margin-right: 0px;
}

.p-tabview .p-tabview-nav li .p-tabview-nav-link {
  background-color: #ffffff;
  font-size:12px;
  border:none;
}

.p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
  color: #007ad9;
  border-width: 0;
  background:#ffffff;
}

.p-tabview .p-tabview-nav li .p-tabview-nav-link:not(.p-disabled):focus {
  box-shadow:none;
}

.p-tabview-panels {
  border:none;
}

.p-overlaypanel {
  z-index:10001 !important;
}

.hideButtonIcon {
  span.p-button-icon {
    // visibility: hidden;
    display: none;
  }
}

.p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
  background-color: var(--button-background-color);
}

#workOrderTable_statusFilterList {
  .p-listbox.p-component {
      border:none !important;
  }

  .p-listbox-header {
      display:none;
  }
}

.p-overlaypanel-content:has(> #workOrderTable_statusFilterList) { 
  padding:0;
}

.p-listbox .p-listbox-list .p-listbox-item.p-highlight {
  background-color: var(--button-background-color);
}

.p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
  color:var(--button-background-color);
}

.p-button.p-button-text:enabled:active {
  border-color: transparent;
  background-color: transparent;
}

.p-button:focus {
  box-shadow: none;
}

.p-checkbox .p-checkbox-box.p-highlight {
  color:var(--button-background-color);
  background:var(--button-background-color);
}

.dangerButton {
  background-color: var(--button-reject-background-color) !important;
}

.overlayMarkerImage{
  position: absolute;
  width: 30px;
  height: 30px;
  z-index:1;
  cursor: pointer;
}

.operateByImageCanvas {
  position:absolute;
  cursor: crosshair;
}

.sideMenuOptionDetailIconNotify::after {
  content: "";
  width: 8px;
  height: 8px;
  top: -5px;
  right: 0px;
  border-radius: 50%;
  background: #e91224;
  display: inline-block;
  position: absolute;
}

.notificationIconButton > span.p-button-icon::after {
  content: "";
  width: 8px;
  height: 8px;
  top: 5px;
  right: 5px;
  border-radius: 50%;
  background: #e91224;
  display: inline-block;
  position: absolute;
}