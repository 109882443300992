// Colors
$theme-colors: (
    "primary": #2C95DD,
    "success": #11aa75,
    "info":    #80bfeb,
    "warning": #f7b80b,
    "danger":  #e64121,
    "dark":    #051936,
    "light":   #cccccc,
);

// Fonts
$font-family-sans-serif:  Roboto, Arial, sans-serif;

// font size ~14px
$font-size-base: .875rem !default;
$h1-font-size:      2rem !default;
$h2-font-size:    1.5rem !default;
$h3-font-size:  1.375rem !default;
$h4-font-size:  1.125rem !default;
$h5-font-size:      1rem !default;
$h6-font-size:   .875rem !default;

$navbar-padding-y: 0px;
$navbar-padding-x: 0px;
